
import {defineComponent} from 'vue'
import {mapState} from "vuex";

export default defineComponent({
  name: "CopyrightComponent",
  computed: {
    ...mapState(['darkMode']),
    logo() {
      if(!this.darkMode) {
        return '/img/logo.png';
      }

      return '/img/logo-dark.png';
    },
  }
})
