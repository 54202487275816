
import {defineComponent} from 'vue'
import {mapState} from "vuex";
import { Swiper, SwiperSlide } from 'swiper/vue';
import {Navigation} from "swiper/modules";


export default defineComponent({
  name: "PortfolioComponent",
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    ...mapState(['portfolio'])
  },
  data() {
    return {
      modules: [Navigation]
    }
  },
  methods: {
    openLink(link: string) {
      window.open(link, "_blank");
    }
  }
})
