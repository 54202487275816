
import {defineComponent} from 'vue'
import {mapState} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default defineComponent({
  name: "QualificationsComponent",
  components: {FontAwesomeIcon},
  computed: {
    ...mapState(['darkMode', 'qualifications']),
    qualificationsImage() {
      if(!this.darkMode) {
        return '/img/qualifications.png';
      }

      return '/img/qualifications-dark.png';
    }
  },
})
