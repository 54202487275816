
import {defineComponent} from 'vue'
import {mapState} from "vuex";

export default defineComponent({
  name: "WhoAmIComponent",
  computed: {
    ...mapState(['darkMode']),
    whoAmIImage() {
      if(!this.darkMode) {
        return '/img/who-am-i.png';
      }

      return '/img/who-am-i-dark.png';
    }
  },
})
