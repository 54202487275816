
import {defineComponent} from 'vue'
import {BCol, BContainer, BForm, BFormGroup, BFormInput, BFormTextarea, BRow} from "bootstrap-vue-next";
import useVuelidate from "@vuelidate/core";
import {email, required} from "@vuelidate/validators";
import axios from "axios";
import { toast } from 'vue3-toastify';

export default defineComponent({
  name: "MessageMeComponent",
  components: {
    BContainer,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        subject: "Project Enquiry",
        message: ""
      }
    }
  },
  validations () {
    return {
      form : {
        name: {
          required,
          min: 3
        },
        email: {
          required,
          email
        },
        subject: {
          required
        },
        message: {
          required,
          min: 10
        }
      }
    }
  },
  methods: {
    async submitForm () {
      const isFormCorrect = await this.v$.$validate()

      if (!isFormCorrect) {
        return
      }

      axios.defaults.headers.post['Content-Type'] = 'application/json';
      axios.post("https://formsubmit.co/ajax/a1f45e4a6dde58f1354ab56537717ad9", this.form).then(() => {
        this.resetForm();
        toast.success('Your message has been submitted.',
            {
              position: "top-right"
            });
      })
          .catch(() => {
            toast.error('There was a problem submitting your message.',
                {
                  position: "top-right"
                });
          });

    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        subject: "Project Enquiry",
        message: ""
      }

      this.v$.$reset();
    }
  }
})
