
import {defineComponent} from 'vue'
import {mapState} from "vuex";

export default defineComponent({
  name: "BannerComponent",
  computed: {
    ...mapState(['darkMode'])
  },
})
