import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66bee126"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "qualification-grade" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_BCol = _resolveComponent("BCol")!
  const _component_BRow = _resolveComponent("BRow")!
  const _component_BContainer = _resolveComponent("BContainer")!

  return (_openBlock(), _createBlock(_component_BContainer, { class: "qualifications" }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: _ctx.qualificationsImage,
        class: "qualifications__image"
      }, null, 8, _hoisted_1),
      _createVNode(_component_BRow, {
        class: "qualifications-list",
        "align-v": "stretch"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.qualifications, (qualification) => {
            return (_openBlock(), _createBlock(_component_BCol, {
              cols: "12",
              md: "6",
              lg: "3",
              key: qualification.title,
              class: "qualifications-list-column"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["qualifications-list__item", { 'qualifications-list__item--dark': _ctx.darkMode }])
                }, [
                  _createElementVNode("h1", null, _toDisplayString(qualification.title), 1),
                  _createElementVNode("h2", null, _toDisplayString(qualification.time), 1),
                  _createElementVNode("p", null, _toDisplayString(qualification.description), 1),
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", null, _toDisplayString(qualification.grade), 1),
                    _createVNode(_component_FontAwesomeIcon, { icon: "fa-duotone fa-check" })
                  ])
                ], 2)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}