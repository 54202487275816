import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationComponent = _resolveComponent("NavigationComponent")!
  const _component_BannerComponent = _resolveComponent("BannerComponent")!
  const _component_WhoAmIComponent = _resolveComponent("WhoAmIComponent")!
  const _component_PortfolioComponent = _resolveComponent("PortfolioComponent")!
  const _component_QualificationsComponent = _resolveComponent("QualificationsComponent")!
  const _component_MessageMeComponent = _resolveComponent("MessageMeComponent")!
  const _component_CopyrightComponent = _resolveComponent("CopyrightComponent")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(["template", { 'template--dark': _ctx.darkMode }])
  }, [
    _createVNode(_component_NavigationComponent, { id: "home" }),
    _createVNode(_component_BannerComponent),
    _createVNode(_component_WhoAmIComponent, { id: "about-me" }),
    _createVNode(_component_PortfolioComponent, { id: "portfolio" }),
    _createVNode(_component_QualificationsComponent, { id: "qualifications" }),
    _createVNode(_component_MessageMeComponent, { id: "message-me" }),
    _createVNode(_component_CopyrightComponent)
  ], 2))
}