
import {BCollapse, BContainer, BNavbar, BNavbarBrand, BNavbarToggle, BRow} from 'bootstrap-vue-next';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {defineComponent} from 'vue'
import {mapState} from "vuex";

export default defineComponent({
  name: "NavigationComponent",
  components: {
    BNavbar,
    BContainer,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    // BRow,
    FontAwesomeIcon
  },
  computed: {
    ...mapState(['darkMode']),
    logo() {
      if(!this.darkMode) {
        return '/img/logo.png';
      }

      return '/img/logo-dark.png';
    },
    navigationIcon() {
      if(!this.darkMode) {
        return 'fa-duotone fa-moon';
      }

      return 'fa-duotone fa-sun';
    },
    navigationLabel() {
      if(!this.darkMode) {
        return 'Dark Mode';
      }

      return 'Light Mode';
    }
  },
  methods: {
    switchDarkMode() {
      this.$store.dispatch('setDarkMode', !this.darkMode);
    },
    navigateTo(location: string) {
      const element = document.getElementById(location);
      if(element) {
        setTimeout(() => {
          element.scrollIntoView({behavior: 'smooth', block: 'end' });
        }, 200);
      }
    }
  }
})
