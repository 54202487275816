import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "portfolio" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Swiper, {
      "slides-per-view": 1,
      navigation: "",
      modules: _ctx.modules,
      "space-between": 0,
      breakpoints: {
            760: {
              slidesPerView: 3
            },
            1200: {
              slidesPerView: 4
            },
            1400: {
              slidesPerView: 5
            }
           }
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.portfolio, (item) => {
          return (_openBlock(), _createBlock(_component_SwiperSlide, {
            key: item.image
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "portfolio-item",
                style: _normalizeStyle(`background: url('${item.image}'); background-size: cover; background-position: center center; background-repeat: no-repeat;`),
                onClick: ($event: any) => (_ctx.openLink(item.link))
              }, null, 12, _hoisted_2)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["modules"])
  ]))
}