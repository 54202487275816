
import {defineComponent} from "vue";
import {mapState} from "vuex";
import NavigationComponent from "@/components/NavigationComponent.vue";
import BannerComponent from "@/components/BannerComponent.vue";
import WhoAmIComponent from "@/components/WhoAmIComponent.vue";
import PortfolioComponent from "@/components/PortfolioComponent.vue";
import QualificationsComponent from "@/components/QualificationsComponent.vue";
import MessageMeComponent from "@/components/MessageMeComponent.vue";
import CopyrightComponent from "@/components/CopyrightComponent.vue";

export default defineComponent({
  name: 'App',
  components: {
    CopyrightComponent,
    MessageMeComponent,
    QualificationsComponent, PortfolioComponent, WhoAmIComponent, BannerComponent, NavigationComponent},
  computed: {
    ...mapState(['darkMode'])
  },
  beforeMount() {
    const darkMode = localStorage.getItem('darkMode');

    if(darkMode != null) {
      this.$store.dispatch('setDarkMode', darkMode === "true");
    }else{
      const systemSettingDark = window.matchMedia("(prefers-color-scheme: dark)");
      this.$store.dispatch('setDarkMode', systemSettingDark.matches);
    }
  },
  watch: {
    darkMode(value) {
      if(value) {
        document.body.classList.add("template--dark");
      }else{
        document.body.classList.remove("template--dark");
      }
    }
  }
})
